<template>
  <div class="casa">
    <h1><a href="https://github.com/PiahDoNeumann/mj-21">Por que a CasaMot - Motilidade em Peixes?</a></h1>
    <p>
      Estudo de Sistema Casa (Computer-assisted sperm analysis ou Analise espermatica assistida por computador) para Peixes.
    </p>
    <p>
      <a href="https://github.com/PiahDoNeumann/mj-21">Material disponivel</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ParceiroCasa',
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
